/**********
 * Theme file: sytle-aachen-v1.scss
 *
 * Bistum Aachen CSS - Variation 1.
 */

// Main theme colors
$main-theme:                    #00aaa0; // "Bistumsgrün"
$main-theme-hover:              #00ddd0; // "Bistumsgrün" hell
$main-theme-complementary:      #004440; // dark cyan



// import common variables for Aachen
@import 'style-aachen-variables.scss';

// Fonst adjustments
$font-header:   "Open Sans", sans-serif;
$font-content:  "Open Sans", sans-serif;
$font-content-size:             1.4rem;

// Border radius adjustments
$default-border-radius:         4px;
$btn-border-radius:             2px;

// Navigation adjustments
$nav-bg-hover:                      #ededed;
$nav-text-hover:                    $text-dark;
$nav-header-text-hover:             #fff;
$nav-header-bg:                     $main-theme;
$nav-header-menu-bg:                $main-theme;
$nav-header-menu-bg-hover:          $main-theme-hover;
$nav-header-text:                   #fff;
$nav-header-text-active:            #fff;
$nav-header-text-transform:         initial;
$nav-header-ver-padding:            15px;
$nav-header-hor-padding:            12px;
$nav-header-show-arrows:            false;
$nav-header-border-size:            0;
$nav-mobile-position:               right;
$nav-mobile-side-content-move:      false;
$nav-mobile-show-box:               true;
$nav-breadcrump-text:               $text-dark;

// $social-links-size:         28px;
$social-links-margin:       4px;
// $social-links-size-half:    $social-links-size / 2;
$social-links-box-height:   30px;

// Footer adjustments
$foot-main-bg-color:            $main-theme;
$foot-main-text-color:          #fff;
$foot-main-link-color:          #fff;
$foot-main-link-hover-color:    #fff;

$foot-sub-bg-color:             #bababa;
$foot-sub-text-color:           $text-dark;
$foot-sub-link-color:           #111;
$foot-sub-link-hover-color:     $main-theme-hover;


// map colors (for marker groups)
$map-colors: ($main-theme, #F18F01, #AD343E, #5BC0EB, #9C89B8);


// Import the Apollo default files
@import '../../../apollo-template/apollo-src/scss-themes/imports';

// Import the Bistuemer specific extensions
@import '../scss/imports-all';

// Import Mainz common styles
@import 'style-aachen-commons.scss';

// Special Navigation styles

@media (max-width: $screen-mobile-max) {
    .ap-header .nav-sidelogo>.sidelogo,
    .ap-header .mainheader .head-navbar .navbar-collapse {
        background-color: #fff;
    }
    .ap-header .nav-sidelogo>.sidelogo img {
        margin-top: 4px;
        margin-left: 10px;
    }

    .ap-header .ac-mobile-image .ap-image {
        max-width: 300px;
    }
}

.ap-header.ac-header.fullslider {

    margin-bottom: 0;

    .ap-slick-slider {
        &.text-below-on-xs .slick-list,
        .image-src-box,
        .ap-slick-slide {
            border-radius: 0;
        }
    }
}

@media (min-width: $screen-desktop-min) {

    .ap-header {

        .head-navbar {
            background-color: $main-theme;
        }

        // Adjustmensts for social icons in header
        .ap-nav-header > .ap-social {
            margin-top: 11px;

            li a:not(:hover) {
                background: #fff;
                * {
                    color: $main-theme;
                }
            }
            li:first-of-type {
                margin-left: 4px;
            }
        }

        // Adjsut padding on default "search" button because of the social icons to the right
        .nav li#searchButtonHeader {
            &.dropdown > a {
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        &.ac-header {
            .row.hidden-notxixed {
                position: relative;
            }

            // Logo in the header "top" container, displayed if fixed header
            .ac-tophead-logo {
                width: 172px;
                position: absolute;
                z-index: $overflow-z-index;

                .ap-image-section .ap-image {
                    position: relative;
                    .image-src-box {
                        background-color: #fff;
                        z-index: $overflow-z-index + 1;
                    }
                }
            }

            // Logo in the header "foot" container, displayed if not fixed header
            .ac-subhead-logo {
                position: relative;

                > div.ap {
                    position: absolute;
                    width: 100%;
                    z-index: $overflow-z-index;
                    top: -60px;
                }

                .ap-image-section .ap-image {
                    // background-color: #fff;
                    border-radius: $default-border-radius;

                    .image-src-box {
                        background-color: inherit;
                    }
                }
            }

            /*
            Workaround to get a "half filled" navbar div, using an SVG background image of the color.
            <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect width='10' height='10' style='fill:#00aaa0' /></svg>
            url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+PHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBzdHlsZT0nZmlsbDojMDBhYWEwJyAvPjwvc3ZnPg==");
            Problem: IE 10 won't work, so use PNG instead of SVG
            */
            .head-navbar {
                background-image:
                    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAIAAAACUFjqAAAAEUlEQVR4AWNgWLUADxqVxooA07GA6ZBpUMsAAAAASUVORK5CYII=");
                background-position: right;
                background-repeat: repeat-y;
                background-size: 40%;
                background-color: transparent;
            }

            // apply round border to begin of "half filled" navbar.
            .sticky {
                > .notfixed {
                    .head-navbar {
                        ul.nav {
                            > li:nth-child(2) > a { // there is a hidden first li child
                                border-bottom-left-radius: $default-border-radius;
                                border-top-left-radius: $default-border-radius;
                            }
                        }
                    }
                }
            }

            @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                .nav > li > a {
                   font-size: 12px;
                   padding: 15px 8px;
                }
            }

            // Make sure logo is not hitting content
            .ac-subhead-logo {
                max-width: 140px;
            }
            .ac-tophead-links {
                .ap-linksequence-hf {
                    margin: 5px 0;
                    min-height: 20px !important;
                }
            }
            @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                .ac-subhead-logo > div.ap {
                    top: -78px;
                }
            }

            &.fullslider {
                .pullnav-true.notfixed {

                    .head-navbar {
                        bottom: round(-0.5 * $nav-header-total-height);
                    }

                    .ap-slick-slider {
                        .ap-slick-slide {
                            .caption.bottom {
                                bottom: 10px + $default-margin + round(0.5 * $nav-header-total-height);
                            }
                            .copyright {
                                bottom: round(0.5 * $nav-header-total-height);
                            }
                        }
                        .slick-dots {
                           bottom: 20px + round(0.5 * $nav-header-total-height);
                        }
                    }

                    .ac-subhead-logo-v2 > .ap-section {
                        position: absolute;
                        left: 0;
                        top: -30px;
                        z-index: 6;
                        width: 150px;
                        background-color: white;
                        padding: 0 10px 5px 2px;
                    }

                    @media (min-width: $screen-lg-min) {
                        .ac-subhead-logo-v2 > .ap-section {
                            width: 173px;
                        }
                    }
                }
            }

            &.newlogo {
               .pullnav-true.notfixed {
                    .ac-subhead-logo-v2 > .ap-section {
                        background-color: transparent;
                        padding: 0;
                        top: -32px;

                        .ap-image,
                        .image-src-box,
                        .image-outer-box,
                        .image-inner-box {
                            border-radius: 0;
                        }
                    }
                }

                .ac-subhead-logo {
                    max-width: 145px;
                }

                .ac-subhead-logo > .ap-section {
                    top: -82px;

                    .ap-image-section .ap-image {
                        background-color: transparent;
                    }

                    .ap-image,
                    .image-src-box,
                    .image-outer-box,
                    .image-inner-box {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}

// Workaround for special position of logo in group editor
html.opencms-group-editor {
    .ap-header.ac-header {
        .ac-subhead-logo {
            > div.ap {
                position: static;
                width: 100%;
            }
        }
    }
}

// Colored row adjustments for height, center etc.
section.colored-row {

    .ap-section .head {
        text-align: center;
        h1, h2, h3 {
            color: $main-theme;
        }
        h1, h2 {
            font-size: 34px;
            padding-bottom: 20px;
        }
    }

    .pagination-center .list-pagination {
        display: flex;
        justify-content: center;
    }

    .list-pagination button.loadMore:not(:hover) {
        background-color: transparent;
        color: $main-theme;
    }
}

.ap-slick-slider.square-text {
    .ap-slick-slide {
        .caption {
            top: initial !important;
            right: initial !important;
            left: 0 !important;
            bottom: 0 !important;
            background: rgba(0,0,0,.5) !important;
            opacity: 1 !important;
            width: 100%;
            max-width: 100%;
            padding: 10px 20px;

            h2, h3 {
                font-size: 2.0rem;
                line-height: $square-overlay-line-height;
                text-shadow: 1px 1px 1px #000;
                margin: 0;
                -webkit-font-smoothing: initial;
                -moz-osx-font-smoothing: initial;
            }
            h3 {
                font-size: 1.6rem;
            }
            h2 + h3 {
                margin-top: 5px;
            }
        }
    }
    .slick-dots {
        bottom: initial;
    }
}